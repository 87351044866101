<template>
  <div>
    <v-container fluid>
      <div
        :id="block_id"
        class="statistics-box position-relative uvr-documents-list d-flex"
      >
        <v-col
          id="uvr-presented-documents"
          v-click-outside="checkOrderAndUpdate"
          cols="8"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <span class="font-weight-bold">
                44 Документы ({{ presented_documents.length }})
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="disabledView ? true: !selectedDocs.length"
                      @click="deleteDocumentArr"
                    >
                      Удалить выбранные документы
                    </v-list-item>
                    <v-list-item
                      :disabled="disabledView ? true: !selectedDocs.length"
                      @click="hideTooltips = !hideTooltips"
                    >
                      {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>

            <v-col
              cols="12"
            >
              <v-btn
                v-if="!presented_documents.length"
                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addNewDocument"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
              <div class="d-flex px-1">
                <div class="header__select">
                  <v-checkbox
                    tabindex="-1"
                    dense
                    hide-details="true"
                    color="#5CB7B1"
                    :value="isAllSelected"
                    @change="checkAll"
                  />
                </div>
                <div class="header__sort">
                  <small>№ п/п</small>
                </div>
                <div class="header__code">
                  <small>Код</small>
                </div>
                <div class="header__number">
                  <small>Номер документа</small>
                </div>
                <div class="header__date">
                  <small>Дата</small>
                </div>
                <div
                  v-if="disabledView"
                  class="header__bd44"
                >
                  <small>БД44</small>
                </div>
              </div>
              <v-virtual-scroll
                bench="10"
                height="300px"
                item-height="50px"
                :items="presented_documents"
                class="drop-zone"
                @drop="onDrop($event, 1)"
                @dragover.prevent
                @dragenter.prevent
              >
                <template #default="{ index, item }">
                  <single-document
                    :ref="'doc_' + index"
                    :key="'doc_' + index"
                    :item="item"
                    :index="index"
                    :for-single-item="false"
                    :draggable.sync="draggable"
                    :hide-tooltips="hideTooltips"
                    :selected-docs="selectedDocs"
                    :presented-documents="presented_documents"
                    :control="controlPath"
                    @select="onSelect"
                    @delete="deleteDocument"
                    @addNewDoc="addNewDocument"
                  />
                </template>
              </v-virtual-scroll>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <about-documents />
        </v-col>
      </div>
    </v-container>
    <component
      :is="activeComponent"
      :ids="selectedDocs"
      :show.sync="show"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SingleDocument from "@/components/uvr/documents/single-document.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { presented_documents as onPresentedDocuments } from "@/events/statistics/control";
import highlightFieldsParent from "@/mixins/control/highlight-fields-parent";
import DocumentsDropZoneMixin from "@/mixins/draggable/documents-drop-zone.mixin";
import AboutDocuments from "@/components/uvr/documents/about-documents.vue";

export default {
  components: {
    AboutDocuments,
    SingleDocument,
  },
  mixins: [highlightFieldsParent, DocumentsDropZoneMixin],
  data: () => ({
    show:false,
    block_id:"uvr-presented-documents",
    activeComponent:null,
    selectedDocs: [],
    hideTooltips: true,
    date: null,
    menu: false,
    pin: false,
    presented_documents: [],
    controlSettings: {
      controlBlockSelector: ".uvr-documents-list",
      controlElementSelector: ".uvr-documents-list .v-virtual-scroll",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      editableWare: "uvr/getEditableWare",
      pinned: "ui/getPinned",
      disabledView:'uvr/getVisibility'
    }),
    isAllSelected() {
      return (
        this.selectedDocs.length &&
        this.selectedDocs.length === this.presented_documents.length
      );
    },
    tableHeight() {
      return this.presented_documents.length * 50;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    onPresentedDocuments.subscribe(this.onControl);
  },
  beforeDestroy() {
    onPresentedDocuments.unsubscribe();
  },
  methods: {
    checkAll() {
      if (this.selectedDocs.length === this.presented_documents.length) {
        this.selectedDocs = [];
      } else {
        this.selectedDocs = this.presented_documents.map((i) => i.id);
      }
    },
    onSelect(id) {
      if (this.selectedDocs.includes(id)) {
        this.selectedDocs = this.selectedDocs.filter((i) => i !== id);
      } else {
        this.selectedDocs.push(id);
      }
    },
    setFields() {
      this.presented_documents = this.selected?.presented_documents || [];
    },
    addNewDocument() {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.createDocument();
        });
      } else {
        this.createDocument();
      }
    },
    createDocument() {
      this.$store.dispatch("uvr/addDocument");
    },
    deleteDocument(payload) {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.$store.dispatch("uvr/deleteDocument", payload).catch(() => {
            this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
          })
        });
      } else {
        this.$store.dispatch("uvr/deleteDocument", payload).catch(() => {
          this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
        })
      }
    },
    deleteDocumentArr() {
      this.$store.dispatch("uvr/deleteDocumentArr", this.selectedDocs).catch(() => {
        this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
      });
      this.unsetSelected();
      this.checkOrderAndUpdate();
    },
    updateSortIndexes(presentedDocuments){
      return presentedDocuments.map((document, idx) => {
        return {
          ...document,
          sort_index: idx + 1,
        };
      });
    },
    checkOrderAndUpdate() {
      if (this.orderChanged) {
        const docs = this.updateSortIndexes(this.presented_documents)
        const payload = docs.map((doc) => convertEmptyStringsToNull(doc));
        return this.$store
          .dispatch("uvr/updateDocument", { payload })
          .then(() => {
            this.orderChanged = false;
          })
          .catch(() => this.$error());
      }
    },
    unsetSelected() {
      this.selectedDocs = [];
    },
  },
};
</script>
<style scoped>
.header__sort {
  width: 60px !important;
}
.header__code {
  width: 180px !important;
  padding: 0 10px 0 0;
}
.header__number {
  width: 300px !important;
  padding: 0 10px 0 0;
}
.header__date {
  width: 160px !important;
}
.header__bd44{
  width: 30px !important;
}

.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.document-add {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}
</style>
