<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>      
      <v-col cols="6">
        <div class="statistics-box">
          <div
            class="font-weight-bold align-center col"
          >
            Регистрация в месте хранения
          </div>
          <v-row
            class="pb-4 col"
            :class="{'blocked-box' : blocked_visibility}"
          >
            <v-col
              align-self="end"
              cols="4"
            >
              <label>
                Номер ЗТК/СВХ</label>
              <div>
                <v-autocomplete                    
                  v-model="type_declaration.customs_store_license_number"
                  :items="storagesWithLocationSearch"
                  auto-select-first
                  item-text="text"
                  item-value="licenseNumber"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </div>
            </v-col>
            <v-col
              cols="3"
              align-self="end"
            >
              <label>
                Цель
                <v-autocomplete
                  ref="purpose_code"
                  v-model="type_declaration.purpose_code"
                  :items="purposePlacements"
                  item-value="code"
                  item-text="search"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              align-self="end"
            >
              <label>
                Направление
                <v-autocomplete
                  ref="direction"
                  v-model="type_declaration.direction"
                  :items="directions"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              align-self="end"
            >
              <label>
                Признак размещения
                <v-autocomplete
                  v-model="type_declaration.placement_flag"
                  :items="placement_flags"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="px-2"
            >
              <label>
                Вес брутто
                <input-numeric
                  v-model="type_declaration.gross_weight"
                  :fraction="3"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="px-2"
            >
              <label>
                Вес нетто
                <input-numeric
                  v-model="type_declaration.net_weight"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :fraction="3"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        cols="6"
      >
        <div class="statistics-box d-flex">
          <v-col
            cols="4"
          >
            <label>
              А Регистрационный номер
              <field-menu>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                  <v-list-item
                    @click="fillWithCustomsData"
                  >Заполнить данными из таможни</v-list-item>
                  <v-list-item
                    @click="clearRegNumA"
                  >Очистить регистрационный номер и сменить ID документа</v-list-item>
                </v-list>
              </field-menu>
              <v-text-field
                ref="reg_number"
                v-model="registration_details.reg_num_a"
                v-mask="'########/######/#######'"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                class="p-0"
              />
            </label>
          </v-col>
          <v-col cols="4">
            <label>ФИО заявителя</label>
            <v-text-field
              ref="filler_name"
              v-model="registration_details.filler_name"
              outlined
              dense
              hide-details="auto"
              disabled
              background-color="grey lighten-2"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <label>
              УНП заявителя
              <v-text-field
                ref="unp"
                v-model="registration_details.unp"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                class="p-0"
              />
            </label>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import FieldMenu from "@/components/documents/field-menu.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  type_declaration as onTypeDeclaration,
  registration_details as onRegistrationDetails,
} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {FieldMenu, InputNumeric},
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data() {
    return {
      block_id: "uvr-main-info",
      disabled: true,
      menu:false,
      entryDate:null,
      entryTime:null,
      directions:[
        {
          text:"Вывоз",
          value:1
        },
        {
          text:"Ввоз",
          value:2
        },
      ],
      placement_flags: [
        {
          text:"0 - товары размещаются в ЗТК, не являющейся СТЗ",
          value:0
        },
        {
          text:"1 - ввоз на территорию СТЗ, в том числе на территорию портовой СТЗ или логистической СТЗ товаров, помещенных под таможенную процедуру свободной таможенной зоны, в отношении которых подана декларация на товары, в которой отсутствуют сведения о такой СТЗ",
          value:1
        },
      ],
      type_declaration: {
        declaration_id: null,
        direction: null,
        purpose_code: null,
        gross_weight: null,
        net_weight: null,
        placement_flag: null,
        customs_store_license_number: null
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: null,
        reg_num_b: null,
        filler_name:null,
        unp:null
      },
    };
  },
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      customsPoints: 'catalogs/getNsiCustomsPoints',
      purposePlacements: "catalogs/getNsiPurposePlacements",
      storages: "catalogs/getNsiCustomsStorages",
    }),
    storagesWithLocationSearch() {
      return this.storages.map((storage) => ({
        ...storage,
        search: `${storage.licenseNumber} - ${storage.name}-${storage.unp}-${storage.location}`.toLowerCase()
      }))
    },
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
        this.disabled = true
      },
      deep: true
    },
  },
  created() {
    onTypeDeclaration.subscribe(this.onControl);
    onRegistrationDetails.subscribe(this.onControl);
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe();
    onRegistrationDetails.unsubscribe();
  },
  methods: {
    highlightField,
    onControl({path}){
      highlightField.call(this, {path})
    },
    setFields() {
      const {type_declaration} = this.selected || {};
      const {registration_details} = this.selected || {};
      Object.keys(this.type_declaration).forEach(key => {
        if(key in type_declaration){
          this.type_declaration[key] = type_declaration[key];
        }
      })

      Object.keys(this.registration_details).forEach(key => {
        if(key in registration_details){
          this.registration_details[key] = registration_details[key];
        }
      })
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    async uploadData() {
      const type_declaration = this.convertEmptyStringsToNull(this.type_declaration);
      const registration_details = this.convertEmptyStringsToNull(this.registration_details)
      const typeDeclarationResponse = await this.$store.dispatch("uvr/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });
      await this.$store.dispatch("uvr/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
      return Promise.resolve(typeDeclarationResponse).then((res) => {
        this.disabled = true
        return res
      });
    },
  },
};
</script>
<style scoped>
  label{
    margin: 0;
  }
</style>
