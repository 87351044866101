<template>
  <v-row
    class="grey lighten-2 rounded mb-1"
  >
    <v-col
      cols="9"
      class="py-1"
      align-self="center"
    >
      <v-tooltip
        bottom
        max-width="450"
      >
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            class="cursor-pointer"
            v-on="on"
          >{{ file.compactName }}</span>
        </template>
        <span>{{ file.name }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      class="py-1 d-flex justify-end"
    >
      <v-btn
        text
        small
        min-width="30"
        width="30"
        @click="downloadFile"
      >
        <v-icon>mdi-download-outline</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            small
            min-width="30"
            width="30"

            v-on="on"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="deleteFile">
            Удалить
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  methods:{
    downloadFile(){
      this.$store.dispatch("uvr/getDocumentFileForUvr", this.file.name).then((res) => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({data:res.data, type:null, name})
      }).catch(() => this.$error())
    },
    deleteFile(){
      this.$store.dispatch("uvr/deleteDocumentFileForUvr", this.file.name)
        .then(() => this.$info("Файл удален"))
        .then(() => this.$emit("update"))
        .catch(() => this.$error("Не удалось удалить файл"))
    },
  }

}
</script>