<template>
  <v-col>
    <uploaded-file
      v-for="(file, index) in files"
      :key="`file-${index}`"
      :file="file"
      @update="fetchDocumentsList"
    />
    <div />
  </v-col>
</template>
<script>
import UploadedFile from "@/components/uvr/documents/uploaded-file.vue";
import {downloadFile} from "@/helpers/documents";
import {mapGetters} from "vuex";

export default {
  components: {UploadedFile},
  data() {
    return {
      files: []
    }
  },
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
    }),
  },
  watch: {
    selected: {
      handler() { 
        this.setFields()
      },
      deep: true
    }
  },
  methods: {
    downloadAllFiles() {
      return this.$store.dispatch("uvr/getDocumentFilesForUvr").then((res) => {
        downloadFile({data: res.data, type: "application/zip"})
      }).catch(() => this.$error("Не удалось загрузить файлы"))
    },
    setFiles(files) {
      this.files = files
    },
    addCompactFileName(files) {
      return files.map(name => {
        return {
          name,
          compactName: name.length >= 32 ? name.slice(0, 32).concat("...") : name
        }
      })
    },
    fetchDocumentsList() {
      return this.$store.dispatch('uvr/getDocumentFilesNamesForUvr')
        .then(({data}) => this.setFiles(this.addCompactFileName(data)))
        .catch(() => {
          this.$error("Не удалось обновить список файлов")
          this.setFiles([])
        })
    },
    setFields() {
      this.selected

      this.fetchDocumentsList()
    },
  }
}
</script>
