<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template
      :tabs="tabs"
    />
    <uvr-toolbox />
    <uvr-main-info />
    <transport />

    <!--<carrier />
    <driver />-->

    <documents v-if="!pinned.includes('documents')" />
    <!--<previous-reg-numbers />-->

    <shipments />
    <preceding-documents v-if="!pinned.includes('preceding-documents')" />

    <catalogs />
    <shared-buffer />

    <uvr-copy-field-modal />
    <copy-field-to-all-modal />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
  </section>
</template>

<script>
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import UvrToolbox from "@/components/uvr/uvr-toolbox.vue";
import UvrMainInfo from '@/components/uvr/uvr-main-info.vue'
//import Carrier from '@/components/uvr/carrier.vue'
import Transport from '@/components/uvr/transport/transport.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import offsetMixin from '@/mixins/offset.mixin.js'
//import Driver from "@/components/uvr/driver.vue";
import Shipments from '@/components/uvr/shipments/shipments.vue';
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {setDeclarationTitle} from "@/helpers/text";
import {mapGetters} from "vuex";
//import PreviousRegNumbers from "@/components/uvr/previous-reg-numbers/previous-reg-numbers.vue";
import UvrCopyFieldModal from "@/components/uvr/goods/uvr-copy-field-modal.vue";
import Documents from "@/components/uvr/documents/documents.vue";
import PrecedingDocuments from "@/components/uvr/preceding-documents/preceding-documents.vue";

export default{
  components: {
    UvrCopyFieldModal,
    //PreviousRegNumbers,
    DistinctDivisionNotification,
    SideMenu,
    CopyFieldToAllModal,
    SharedBuffer,
    Shipments,
    //Driver,
    PinnedTemplate,
    UvrToolbox,
    UvrMainInfo,
    //Carrier,
    Transport,
    Catalogs,
    Documents,
    PrecedingDocuments
  },
  mixins:[offsetMixin],
  provide:{
    rootNamespace:'uvr'
  },
  data:() => ({
    tabs:[
      {
        name:'controls',
        component:() => import('@/components/uvr/controls/common-errors.vue')
      },
      {
        name:'documents',
        component:() => import('@/components/uvr/documents/documents.vue')
      },
      {
        name:'goods-character',
        component:() => import('@/components/uvr/goods-character.vue')
      },
    ],
    menuItems:[
      {
        name:"Основная информация",
        id:"uvr-main-info"
      },
      {
        name:"Транспорт",
        id:"uvr-about-transport"
      },

      {
        name:"Перевозчик",
        id:"uvr-carrier"
      },
      {
        name:"Водитель",
        id:"uvr-driver"
      },
      null,
      {
        name: "Общие характеристики товара",
        id:"uvr-goods-character"
      },
      {
        name:"Отправитель",
        id:"uvr-sender"
      },
      {
        name:"Получатель",
        id:"uvr-recipient"
      },
      {
        name:"Документы",
        id:"uvr-presented-documents"
      },
      {
        name:"Товары",
        id:"uvr-wares",
        component:WaresScroll,
      },
      {
        name:"Предшествующие документы",
        id:"uvr-preceding-documents"
      },
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      pinned:'ui/getPinned'
    }),
  },
  watch:{
    selected:{
      handler(uvr){
        const {remark, out_number: outNumber} = uvr?.about_declaration ?? {}
        
        setDeclarationTitle({
          outNumber,
          remark,
          prefix:"УВР"
        })
      },
      deep:true
    }
  },
  created(){
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('uvr/fetchNsiAeos')
    this.$store.dispatch('uvr/fetchNsiTransportMarks')
    const id = this.$route.params.id
    this.$store.dispatch("uvr/getDocumentById", id).catch(err => {
      if(err.response.status === 403){
        this.$router.push('/403')
      }
    });

  },
}
</script>
