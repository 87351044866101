<template>
  <div class="shipment-block my-3">
    <!--<goods-character v-if="!pinned.includes('goods-character')" />-->
    <contragent />    
    <!--<uvr-goods />-->
  </div>
</template>
<script>
//import GoodsCharacter from "@/components/uvr/goods-character.vue";
// UvrGoods from "@/components/uvr/goods/uvr-goods.vue";
import Contragent from "@/components/uvr/contragents/contragent.vue";
import {mapGetters} from "vuex";

export default {
  components:{
    Contragent,
    //GoodsCharacter,
    //UvrGoods,
  },
  computed:{
    ...mapGetters({
      pinned:'ui/getPinned'
    })
  }
}
</script>
