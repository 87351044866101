<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row pt-1"
    :draggable="disabledView ? false: draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select ">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </div>
    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>  
    <div class="document__number">
      <v-text-field
        ref="doc_number"
        v-model="document.doc_number"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledView ? 'grey lighten-2': 'white'"
        :disabled="disabledView"
      />
    </div>  
    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>
    
    <v-btn
      text
      :disabled="disabledView"
      tabindex="-1"
      max-width="36px"
      min-width="36px"
      class="elevation-0 ml-1 document-item-delete"
      @click="deleteDocument"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-btn
      v-if="index === precedingDocuments.length - 1"
      text
      class="document-add elevation-0"
      min-width="36px"
      max-width="36px"
      :disabled="disabledView"
      @click="addNewDoc"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {deleteNulls} from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {showCatalog} from "@/events/statistics";
import {documentTypeSelected} from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import {highlightField} from '@/helpers/control'
import {DEAL_REGISTRATION_NOT_REQUIRED} from '@/constants'
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean
    },
    selectedDocs: {
      required: true,
      type: Array
    },
    precedingDocuments: {
      required: true,
      type: Array
    },
    control: {
      required: true,
      type: Object
    },
  },
  data: () => ({
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      id: null,
      doc_number: null,
      doc_date: null,
      sort_index: "",
      declaration_id: "",
    },
    noRegistration: true,
    key: '',
    noRegistrationText: DEAL_REGISTRATION_NOT_REQUIRED,
  }),
  computed: {
    ...mapGetters({
      uvr: "uvr/getSelected",
      disabledView: "uvr/getVisibility"
    }),
    docName() {
      const {name} =
      this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
  },
  watch: {
    control: {
      handler({path, index}) {
        if (path !== "" && this.index === index - 1) {
          this.$nextTick(() => {
            highlightField.call(this, {path})
          })
        }
      },
      deep: true,
      immediate: true
    },
    item: {
      handler(newVal) {
        this.block_id = `uvr-preceding-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    addNewDoc() {
      this.$emit('addNewDoc')
    },
    updateSelected() {
      this.$emit('select', this.item.id)
    },
    startDrag(evt, document) {
      if (this.hasChanges && this.fields_been_visited) {
        return this.$snackbar({
          text: "Сохраните изменения перед сортировкой.",
          color: "orange",
          top: false,
          right: false
        });
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      if (
        [...evt.target.classList].includes("allow-drag") &&
          !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const {id} = this.item;
      const index = this.index;
      this.$emit("delete", {id, index});
    },
    uploadData() {
      const index = this.index;
      const payload = convertEmptyStringsToNull(this.document);
      return this.$store.dispatch("uvr/updatePrecedingDocumentDeclaration", {
        index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
      });
    },
    checkAndUpdateDocType({id}) {
      if (this.document.id === id) {
        this.fieldsBeenVisitedByUser();
      }
    },

  },
};
</script>
<style scoped>
.document__row {
  display: inline-flex;
  align-items: center;
}

.document__select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__code {
  margin-right: 10px;
  width: 140px !important;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__sign {
  width: 160px !important;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__number {
  width: 280px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__date {
  width: 160px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__documents-group{
  width: 400px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__type {
  max-width: 80px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}

.allow-drag {
  cursor: pointer !important;
}
</style>
