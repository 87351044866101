<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    cols="12"
  >
    <v-row>
      <v-col align-self="center">
        <v-checkbox
          v-model="about_documents.safe_package"
          hide-details
          label="Сейф пакет"
          color="teal lighten-2"
          @change="readyToUpdate"
        />
      </v-col>
      <v-col>
        <div class="d-flex">
          <v-btn
            color="teal lighten-2"
            class="elevation-0 rounded-r-0"
            @click="clickInputFile"
          >
            <v-icon>mdi-upload-outline</v-icon>
            Прикрепить файлы
          </v-btn>
          <v-btn
            dark
            class="elevation-0 rounded-l-0"
            @click="downloadAllFiles"
          >
            <v-icon>mdi-download-outline</v-icon>
          </v-btn>
        </div>
        <input
          ref="fileInput"
          multiple
          class="d-none"
          type="file"
          @change="select"
        >
      </v-col>
      <v-col
        v-if="loading"
        cols="12"
        class="py-0"
      >
        <v-progress-linear

          indeterminate
          color="teal"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <uploaded-file-list
          ref="filesList"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import UploadedFileList from "@/components/uvr/documents/uploaded-file-list.vue";

export default {
  components: {UploadedFileList},
  mixins: [blockAutoUpdateMixin],
  data() {
    return {
      block_id: 'uvr-about-documents',
      about_documents: {
        declaration_id: null,
        safe_package: null
      },
      loading:false
    }
  },
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields()
      },
      deep: true
    },
  },
  methods: {
    downloadAllFiles(){
      this.loading = true
      this.$refs.filesList.downloadAllFiles()
        .finally(() => this.loading = false)
    },
    clickInputFile() {
      this.$refs.fileInput.click();
    },
    clearFileInput() {
      this.$refs.fileInput.value = ""
    },
    select(event) {
      this.loading = true
      const files = event.target.files
      if (!files.length) return;
      const formData = new FormData()
      Array.from(files).forEach(file => formData.append("documentFiles", file))
      this.$store.dispatch("uvr/attachDocumentFilesToUvr", formData)
        .then(() => this.$info("Файлы сохранены"))
        .then(this.$refs.filesList.fetchDocumentsList)
        .catch(() => this.$error("Не удалось сохранить файлы"))
        .finally(this.clearFileInput)
        .finally(() => this.loading = false)
    },
    setFields() {
      const {about_documents} = this.selected || {};

      for (let key in about_documents) {
        if (key in this.about_documents) {
          this.about_documents[key] = about_documents[key];
        }
      }    
    },
    readyToUpdate() {
      this.fields_been_visited = true
      this.hasChanges = true
    },
    uploadData() {
      const about_documents = convertEmptyStringsToNull(this.about_documents)
      return this.$store.dispatch("uvr/uploadBlockData", {
        name: "about_documents",
        value: about_documents,
      });
    }
  }
}
</script>
